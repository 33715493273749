import React from "react";
import * as Icon from "react-feather";
import { CgProductHunt } from "react-icons/cg";
import { MdPlace } from "react-icons/md";
import { SiCashapp } from "react-icons/si";
import { AiOutlineOrderedList } from "react-icons/ai";
import { FiTruck, FiPercent } from "react-icons/fi";
import { GiMoneyStack } from "react-icons/gi";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
    // badge: "warning",
    // badgeText: "2",

  },
    //! -----------Home Page---------
    {
      id: "homePage",
      title: "Home",
      type: "item",
      icon: <Icon.Home size={12} />,
      permissions: ["admin", "seo"],
      navLink: "/home-page",
    },

    //!---------------- Categories--------
    {
      id: "categoryList",
      title: "Categories",
      type: "item",
      icon: <Icon.Menu size={12} />,
      permissions: ["admin", 'seo'],
      navLink: "/category/list",
    },
    //!---------------- Categories--------
    {
      id: "subCategoryList",
      title: "Sub Categories",
      type: "item",
      icon: <Icon.Menu size={12} />,
      permissions: ["admin", 'seo'],
      navLink: "/sub-category/list",
    },

  //!---------------- Products--------
  {
    id: "productList",
    title: "Product",
    type: "item",
    icon: <Icon.Package size={12} />,
    permissions: ["admin", "seo"],
    navLink: "/products/list",
  },
  {
    id: "variationitems",
    title: "Variation Items",
    type: "item",
    icon: <Icon.Package size={12} />,
    permissions: ["admin"],
    navLink: "/variationItems/list",
  },
  //!---------------- Areas--------
  {
    id: "areas",
    title: "Areas",
    type: "item",
    icon: <MdPlace size={12} />,
    permissions: ["admin"],
    navLink: "/areas/list",
  },

  //!---------------Orders------
  {
    id: "orders",
    title: "Orders",
    type: "item",
    icon: <Icon.ShoppingCart size={12} />,
    permissions: ["admin"],
    navLink: "/orders",
  },
  //!---------------sales------
  {
    id: "sales",
    title: "Sales",
    type: "item",
    icon: <SiCashapp size={12} />,
    permissions: ["admin"],
    navLink: "/sales",
  },
  //!---------------product Report------
  {
    id: "productReport",
    title: "Product Report",
    type: "item",
    icon: <AiOutlineOrderedList size={12} />,
    permissions: ["admin"],
    navLink: "/product-report",
  },
  //!---------------shipments------
  {
    id: "shipments",
    title: "Shipments",
    type: "item",
    icon: <FiTruck size={12} />,
    permissions: ["admin"],
    navLink: "/shipments",
  },
  //!---------------shipments------
  {
    id: "transactions",
    title: "Transactions",
    type: "item",
    icon: <GiMoneyStack size={12} />,
    permissions: ["admin"],
    navLink: "/transactions",
  },
  //!---------------Promo Code------
  {
    id: "promo-codes",
    title: "Promo Codes",
    type: "item",
    icon: <FiPercent size={12} />,
    permissions: ["admin"],
    navLink: "/promo-codes/list",
  },
  //!---------------Registered Users------
    {
      id: "users",
      title: "Registered Users",
      type: "item",
      icon: <Icon.User size={12} />,
      permissions: ["admin"],
      navLink: "/users-list",
    },
  //!---------------Guest users------
  {
    id: "guest",
    title: "Guest Users",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin"],
    navLink: "/guests",
  },

  //!----------------Sub Categories--------
  // {
  //   id: "subCategoryList",
  //   title: "Sub Categories",
  //   type: "item",
  //   icon: <Icon.ShoppingBag size={12} />,
  //   permissions: ["admin", "seo"],
  //   navLink: "/sub-category/list",
  // },

    //!-----------Blog----------
    {
      id: "blog",
      title: "Blog",
      type: "item",
      icon: <Icon.AlertTriangle size={12} />,
      permissions: ["admin", "seo"],
      navLink: "/blog",
      // children: [
      //   {
      //     id: "blogGrid",
      //     title: "Blog Grid",
      //     type: "item",
      //     icon: <Icon.Circle size={12} />,
      //     permissions: ["admin", "seo"],
      //     navLink: "/blog/list",
      //   },
      //   {
      //     id: "blogItem",
      //     title: "Blog Items",
      //     type: "item",
      //     icon: <Icon.Clipboard size={12} />,
      //     permissions: ["admin", "seo"],
      //     navLink: "/blog-item/list",
      //   },
      // ],
    },

    //!--------------Videos-------
    {
      id: "videosForm",
      title: "Videos",
      type: "item",
      icon: <Icon.Video size={12} />,
      permissions: ["admin"],
      navLink: "/videos/list",
    },
    //!--------------Pages-------
    {
      id: "pages",
      title: "Pages",
      type: "item",
      icon: <Icon.Paperclip size={12} />,
      permissions: ["admin", "seo"],
      navLink: "/pages",
    },
    {
      id: "staticPages",
      title: "Static Pages",
      type: "collapse",
      icon: <Icon.Anchor size={15} />,
      children: [
        {
          id: "home-page",
          title: "Home Page",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "seo"],
          navLink: "/home-page/form",
        },
        {
          id: "about-us",
          title: "About Us",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "seo"],
          navLink: "/about-us/form",
        },
        {
          id: "mission",
          title: "Mission",
          type: "item",
          icon: <Icon.Clipboard size={12} />,
          permissions: ["admin", "seo"],
          navLink: "/mission/form",
        },
        {
          id: "pigeonLogo",
          title: "Pigeon Logo",
          type: "item",
          icon: <Icon.PlayCircle size={12} />,
          permissions: ["admin", "seo"],
          navLink: "/pigeon-logo/form",
        },
        {
          id: "topMessage",
          title: "Top Message",
          type: "item",
          icon: <Icon.PlayCircle size={12} />,
          permissions: ["admin", "seo"],
          navLink: "/top-message/form",
        },
        {
          id: "returnRefund",
          title: "Retun Refund",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "seo"],
          navLink: "/return-refund/form",
        },
      ],
    },
  //!-------Mother and baby world-----------
  {
    id: "motherBabyWorld",
    title: "Mother & Baby World",
    type: "collapse",
    icon: <Icon.Activity size={15} />,
    children: [
      {
        id: "questionAndAnswersForm",
        title: "FAQ",
        type: "item",
        icon: <Icon.HelpCircle size={12} />,
        permissions: ["admin", "seo"],
        navLink: "/faqs/form",
      },
      {
        id: "babyCare",
        title: "Baby Care",
        type: "item",
        icon: <Icon.Book size={12} />,
        permissions: ["admin", "seo"],
        navLink: "/babycare/form",
      },
      {
        id: "firstTimeFather",
        title: "First Time Father",
        type: "item",
        icon: <Icon.Dribbble size={12} />,
        permissions: ["admin", "seo"],
        navLink: "/first-time-father/form",
      },
      //!----------------Moms and Moms-------
      {
        id: "MomsAndMoms",
        title: "Moms & Moms",
        type: "collapse",
        icon: <Icon.Heart size={12} />,
        children: [
          {
            id: "pregnancy",
            title: "Pregnancy",
            type: "item",
            icon: <Icon.Bell size={12} />,
            permissions: ["admin", "seo"],
            navLink: "/pregnancy/form",
          },
          {
            id: "postPregnancyForm",
            title: "Post Pregnancy Form",
            type: "item",
            icon: <Icon.LifeBuoy size={12} />,
            permissions: ["admin", "seo"],
            navLink: "/post-pregnancy/form",
          },
        ],
      },
    ],
  },

  //!-------------Good To know-----------
  {
    id: "goodToKnow",
    title: "Good To Know",
    type: "item",
    icon: <Icon.Aperture size={12} />,
    permissions: ["admin"],
    navLink: "/good-to-know/list",
  },
  {
    id: "productFaqs",
    title: "Product Faqs",
    type: "item",
    icon: <Icon.Aperture size={12} />,
    permissions: ["admin", "seo"],
    navLink: "/productsfaq",
  },
  //!--------------BreastFeedingAdvisor--------
  {
    id: "breastFeedingAdvisor",
    title: "Breast Feeding Advisor",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "breastFeedingCategories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin","seo"],
        navLink: "/breast-feeding-advisor/list",
      },
      {
        id: "articleForm",
        title: "Articles",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "seo"],
        navLink: "/article/list",
      },
    ],
  },

  //!---------------Reviews------
  // {
  //   id: "reviews",
  //   title: "Reviews",
  //   type: "item",
  //   icon: <Icon.Star size={12} />,
  //   permissions: ["admin", "seo"],
  //   navLink: "/reviews",
  // },
  
    //!---------------Header------
    {
      id: "header",
      title: "Header",
      type: "item",
      icon: <Icon.Flag size={12} />,
      permissions: ["admin"],
      navLink: "/header",
    },
    //!---------------Footer------
    {
      id: "footer",
      title: "Footer",
      type: "item",
      icon: <Icon.CheckSquare size={12} />,
      permissions: ["admin"],
      navLink: "/footer",
    },
  //!---------------Contact List------
  {
    id: "contact",
    title: "Contact Us",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin"],
    navLink: "/contact-list",
  },
  //!----------Office Info-----------
  {
    id: "officeInfo",
    title: "Office Info",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin"],
    navLink: "/office-info",
  },
  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin"],
    navLink: "/gallery",
  },
  //!-------------Categories Sort--------
  {
    id: "categories_sort",
    title: "Categories Sort",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin"],
    navLink: "/categories-sort",
  },
  //!-------------Products Sort--------
  {
    id: "products_sort",
    title: "Products Sort",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin"],
    navLink: "/products-sort",
  },

  //!---------------- Testimonials --------
  {
    id: "testimonialsList",
    title: "Testimonials",
    type: "item",
    icon: <Icon.Menu size={12} />,
    permissions: ["admin", 'seo'],
    navLink: "/testimonials/list",
  },
  //!------------Static Pages--------
];

export default navigationConfig;
